import React from 'react'
import PropTypes from 'prop-types'

const propTypes = {
}

const defaultProps = {
}
const ServicesPage = () => {
  const a = []
  return(
    <>
    </>
  )
}
ServicesPage.propTypes = propTypes
ServicesPage.defaultProps = defaultProps

export default ServicesPage