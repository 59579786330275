import { Route, Routes } from 'react-router-dom'
import './App.css'
import { routes } from './routes'
import { DashboardLayout } from './layouts'

function App() {
  return (
    <>
      <Routes>
        <Route
          element={ <DashboardLayout/> }
        >
          {
            routes.map((route,index) => (
              <Route
                key={ `route-${index}` }
                path={ route.path }
                element={ route.element }
              />
            ))
          }

        </Route>
      </Routes>
    </>
  )
}

export default App
