import React, { useContext, useEffect, useState } from 'react'
import { Button, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, ModalFooter, ModalHeader, Row, UncontrolledCollapse, UncontrolledDropdown } from 'reactstrap'
import { BilingualMenu, BilingualMenuMobile } from '.'
import { routes } from '../routes'
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faChevronLeft, faChevronRight, faCircle } from '@fortawesome/free-solid-svg-icons'
import { PublicApi } from '../api'
import LanguageContext from '../providers/language-provider'
import style from './banner-layout.module.scss'

const BannerLayout = () => {
  const { id } = useParams()

  const [ banner, setBanner ] = useState([])
  const [ bannerList, setBannerList ] = useState([])
  const [ services, setServices ] = useState([])
  const [ isModalShown, setIsModalShown ] = useState(false)
  const [ isService, setIsService ] = useState(false)
  const [ isHome, setIsHome ] = useState(false)
  const [ activeIndex, setActiveIndex ] = useState(0)
  const [ isCircleClicked, setIsCircleClicked ] = useState(false)

  const { selectedLanguage } = useContext(LanguageContext)
  const location = useLocation()
  const navigate = useNavigate()
  const isActiveRoute = (route) => (
    location.pathname == route.path ||
    location.pathname === route.alternativePath ||
    location.pathname.split('/')[1] === route.path.split('/')[1]
  )
  const isServiceActiveRoute = (service) => (
    location.pathname.split('/')[2] == service.id
  )

  useEffect(() => {
    const path = location.pathname.split('/')
    let filterLocation = path[ 1 ]
    if (!filterLocation) {
      filterLocation = 'home'
    }
    if (filterLocation === 'services') {
      PublicApi.showService(id)
        .then((response) => {
          const service = response.data.data
          setBanner(service)
          setIsService(true)
        })
        .catch((error) => {
          alert('Something is wrong')
          console.log(error)
        })
    } else {
      PublicApi.indexBanners({ location: filterLocation })
        .then((response) => {
          const bannerResponse = response.data.data
          setBannerList(bannerResponse)
          setBanner(bannerResponse[ 0 ])
          setIsService(false)
        })
        .catch((error) => {
          alert('Something is wrong')
          console.log(error)
        })
    }
  }, [ location ])

  useEffect(() => {
    PublicApi.indexServices()
      .then((response) => {
        const listService = response.data.data
        setServices(listService)
      })
      .catch((error) => {
        alert('Something is wrong')
        console.log(error)
      })
  }, [])

  useEffect(() => {
    const path = location.pathname.split('/')
    let filterLocation = path[ 1 ]
    if (!filterLocation || filterLocation==='home') {
      setIsHome(true)
    } else {
      setIsHome(false)
    }
  }, [ location.pathname ])

  const toggleMobileMenu = () => {
    setIsModalShown(!isModalShown)
  }
  const handleOpenMobileMenu = () => {
    setIsModalShown(true)
  }
  const handleClickService = (service) => {
    console.log(service.id)
    navigate(`/services/${service?.id}`)
  }
  const handleClickBanner = (index) => {
    setIsCircleClicked(true)

    setTimeout(() => {
      setBanner(bannerList[ index ])
    }, 200)
    setTimeout(() => {
      setActiveIndex(index)
      setIsCircleClicked(false)
    }, 700)
  }
  const handleNextBanner = () => {
    setIsCircleClicked(true)
    const hasNext = activeIndex < bannerList.length - 1
    let newIndex = hasNext ? activeIndex + 1 : 0
    setTimeout(() => {
      setBanner(bannerList[ newIndex ])
    }, 200)
    setTimeout(() => {
      setActiveIndex(newIndex)
      setIsCircleClicked(false)
    }, 700)
  }
  const handlePrevBanner = () => {
    setIsCircleClicked(true)
    const hasPrev = activeIndex > 0
    let newIndex = hasPrev ? activeIndex - 1 : bannerList.length-1
    setTimeout(() => {
      setBanner(bannerList[ newIndex ])
    }, 200)
    setTimeout(() => {
      setActiveIndex(newIndex)
      setIsCircleClicked(false)
    }, 700)
  }

  const [touchStart, setTouchStart] = useState(null)
  const [touchEnd, setTouchEnd] = useState(null)

  // the required distance between touchStart and touchEnd to be detected as a swipe
  const minSwipeDistance = 50

  const onTouchStart = (e) => {
    if (!isHome)
      return
    setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX)
  }

  const onTouchMove = (e) => {
    if (!isHome)
      return
    setTouchEnd(e.targetTouches[ 0 ].clientX)
  }

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd || !isHome) return
    const distance = touchStart - touchEnd
    const isLeftSwipe = distance > minSwipeDistance
    const isRightSwipe = distance < -minSwipeDistance

    if (isLeftSwipe) {
      handlePrevBanner()
    }
    if (isRightSwipe) {
      handleNextBanner()
    }
  }

  useEffect(() => {
    if (!isHome)
      return
    const intervalId = setInterval(() => {  //assign interval to a variable to clear it.
      handleNextBanner()
    }, 7000)
    return () => clearInterval(intervalId)
  }, [handleNextBanner])

  const detail = (banner) => banner?.details?.find((det) => det.language.code === selectedLanguage)
  return (
    <>
      <div
        className='position-relative'
        style={{
          width  : '100%',
          height : '600px'
        }}
        onTouchStart={ onTouchStart }
        onTouchMove={ onTouchMove }
        onTouchEnd={ onTouchEnd }
      >
        <img
          className={ `position-absolute ${style.centeredImage} ${isCircleClicked ? style.fadeOut : style.fadeIn}` }
          src={ banner?.media?.mediaUrl }
          alt="Background Banner"
          width={ '100%' }
          height={ 600 }
          style={{
            zIndex         : -3,
            objectFit      : 'cover',
            objectPosition : 'top center'
          }}
        />
        <div
          className={ `position-absolute ${style.centeredImage}` }
          style={{
            zIndex          : -2,
            opacity         : '100%',
            backgroundImage : 'linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 90%)',
            width           : '100%',
            height          : '600px'
          }}
        />

        {
          isHome && (
            <>
              <div
                className={ 'd-flex justify-content-center position-absolute mx-auto text-center' }
                style={{
                  bottom : 0,
                  left   : 0,
                  right  : 0,
                  margin : '0 auto'
                }}
              >
                {
                  bannerList.map((x, index) => (
                    <FontAwesomeIcon
                      key={ `banner-circle-${index}` }
                      className={ `m-1 border border-white rounded-circle border-3 ${activeIndex===index ? 'text-white' : 'text-secondary'}` }
                      icon={ faCircle }
                      onClick={ () => handleClickBanner(index) }
                      style={{
                        cursor: 'pointer'
                      }}
                    />
                  ))
                }
              </div>
              <div
                className={ 'd-flex justify-content-center position-absolute mx-auto text-center' }
                style={{
                  top     : '70%',
                  opacity : '50%',
                  left    : '10%',
                  margin  : '0 auto'
                }}
              >
                <FontAwesomeIcon
                  className={ 'm-1 text-white' }
                  icon={ faChevronLeft }
                  size='2x'
                  onClick={ handlePrevBanner }
                  style={{
                    cursor: 'pointer'
                  }}
                />
              </div>
              <div
                className={ 'd-flex justify-content-center position-absolute mx-auto text-center' }
                style={{
                  top     : '70%',
                  opacity : '50%',
                  right   : '10%',
                  margin  : '0 auto'
                }}
              >
                <FontAwesomeIcon
                  className={ 'm-1 text-white' }
                  icon={ faChevronRight }
                  size='2x'
                  onClick={ handleNextBanner }
                  style={{
                    cursor: 'pointer'
                  }}
                />
              </div>
            </>
          )
        }
        <Container className='d-none d-lg-block'>
          <BilingualMenu/>
          <Row >
            <Col md={ 1 }>
              <img
                className={ '' }
                src="/logo-prisma.svg"
                alt="Logo"
                width={ 87 }
                height={ 53 }
              />
            </Col>
            <Col md={ 11 } className='d-flex justify-content-center align-items-center'>
              {
                routes.map((route) => (
                  !route.invisible && (
                    route.label === 'Our Services'
                      ? (
                        <UncontrolledDropdown
                          key={ `route-${route.label}` }
                        >
                          <DropdownToggle
                            nav
                            className={ `me-3 ${isActiveRoute(route) ? 'text-primary text-decoration-underline' : 'text-black text-decoration-none'}` }
                          >
                            { route.label }
                          </DropdownToggle>
                          <DropdownMenu>
                            <div
                              style={{
                                minWidth: 300
                              }}
                            >
                              {
                                services.map((service, index) => (
                                  <DropdownItem
                                    onClick={ ()=>handleClickService(service) }
                                    key={ `service-children-${index}` }
                                  >
                                    { service?.originalTitle }
                                  </DropdownItem>
                                ))
                              }
                            </div>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      )
                      : (
                        <NavLink
                          key={ `route-${route.label}` }
                          to={ route.alternativePath ? route.alternativePath : route.path }
                          className={ `me-3 ${isActiveRoute(route) ? 'text-primary' : 'text-black text-decoration-none'}` }
                        >
                          { route.label }
                        </NavLink>
                      )
                  )
                ))
              }
            </Col>
          </Row>
        </Container>
        <Container
          className='d-block d-lg-none d-flex align-items-center'
          style={{
            position        : 'fixed',
            top             : 0,
            backgroundColor : 'white',
            zIndex          : 999
          }}
        >
          <img
            className={ '' }
            src="/logo-prisma.svg"
            alt="Logo"
            width={ 87 }
            height={ 53 }
          />
          <div className='ms-auto d-flex align-items-center'>
            <BilingualMenuMobile />
            <Button
              color='transparent'
              onClick={ handleOpenMobileMenu }
            >
              <FontAwesomeIcon icon={ faBars } />
            </Button>
          </div>
        </Container>
        <Container className='pt-5 pt-md-0'>
          <div className='w-100 mt-5 pt-5'>
            <div
              className='h1 text-uppercase fw-bold'
              style={{
                maxWidth: '30rem'
              }}
            >
              { detail(banner)?.title }
            </div>
            {
              !isService && (
                <div
                  className='text-subtitle text-black fw-bold'
                  style={{
                    maxWidth: '30rem'
                  }}
                >
                  { detail(banner)?.subtitle }
                </div>
              )
            }

          </div>
        </Container>
      </div>
      <Modal
        isOpen={ isModalShown }
        toggle={ toggleMobileMenu }
        className='rounded'
        style={{
          overflow : 'hidden',
          opacity  : '98%'
        }}
      >
        <ModalBody
          style={{
            height          : '90vh',
            width           : '100%',
            backgroundColor : '#333333'
          }}
          className='d-flex flex-column'
        >
          <Button
            className='ms-auto rounded align-items-end'
            color='danger'
            onClick={ toggleMobileMenu }
          >
            { 'X' }
          </Button>
          {
            routes.map((route) => (
              !route.invisibleMobile &&
              (
                route.label === 'Our Services'
                  ? (
                    <div key={ `route-${route.label}` } className='d-flex flex-column h2 my-4 ps-4'>
                      <NavLink
                        color='transparent'
                        id="toggler"
                        className={ `me-3 h2 ${isActiveRoute(route) ? 'text-primary text-decoration-underline' : 'text-white text-decoration-none'}` }
                      >
                        { route.label }
                      </NavLink>
                      <UncontrolledCollapse
                        toggler="#toggler"
                      >
                        {
                          services.map((service, index) => (
                            <div key={ `route-mobile-${index}` } className='d-flex flex-column h2 my-4 ps-4'>
                              <NavLink
                                to={ `/services/${service.id}` }
                                className={ `me-3 ${isServiceActiveRoute(service) ? 'text-primary' : 'text-white text-decoration-none'}` }
                                onClick={ toggleMobileMenu }
                              >
                                { service?.originalTitle }
                              </NavLink>
                            </div>
                          ))
                        }
                      </UncontrolledCollapse>
                    </div>
                  )
                  : (
                    <div key={ `route-${route.label}` } className='d-flex flex-column h2 my-4 ps-4'>
                      <NavLink
                        to={ route.alternativePath ? route.alternativePath : route.path }
                        className={ `me-3 ${isActiveRoute(route) ? 'text-primary' : 'text-white text-decoration-none'}` }
                        onClick={ toggleMobileMenu }
                      >
                        { route.label }
                      </NavLink>
                    </div>
                  )
              )
            ))
          }
        </ModalBody>
      </Modal>
    </>
  )
}


export default BannerLayout