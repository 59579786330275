import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'reactstrap'
import PropTypes from 'prop-types'
import { LANGUAGES, SAFETY_COM_ARTICLE } from '../../constants'
import { PublicApi } from '../../api'

const propTypes = {
  selectedLanguage: PropTypes.string
}

const defaultProps = {
  selectedLanguage: ''
}
const SafetyCommitmentSection = ({ selectedLanguage }) => {
  const [ article, setArticle ] = useState({})


  useEffect(() => {
    PublicApi.indexArticles({ location: 'safety-commitment' })
      .then((response) => {
        const listArticles = response.data.data
        setArticle(listArticles[ 0 ])
      })
      .catch(() => {
        alert('Something is Wrong')
      })
  }, [ selectedLanguage ])

  const detail = (thing) => thing?.details?.find((det) => det.language.code === selectedLanguage)

  return (
    <section
      style={{
        backgroundColor : '#022C93',
        width           : '100%'
      }}
    >
      <Container className='py-5'>
        <h2 className='text-white mb-4 mt-1 text-center'>
          <strong>{ 'Safety' }</strong>{ ' Commitment' }
        </h2>
        <h2 className='text-white mb-4 mt-1 text-center fw-lighter'>
          { detail(article)?.title }
        </h2>
        <Row className='mt-5'>
          <Col xs={ 12 } md={ 6 } className='mb-3'>
            <img
              src="/1.jpeg"
              alt="Safety Commitment"
              className='position-relative align-item-center rounded border-white border'
              width={ '100%' }
            />
          </Col>
          <Col xs={ 12 } md={ 6 } className='text-white text-left'>
            <span dangerouslySetInnerHTML={{ __html: detail(article)?.content }} />
          </Col>
        </Row>
      </Container>
    </section>
  )
}
SafetyCommitmentSection.propTypes = propTypes
SafetyCommitmentSection.defaultProps = defaultProps
export default SafetyCommitmentSection