import React, { useContext, useEffect, useState } from 'react'
import ReactCountryFlag from 'react-country-flag'
import { Button, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'
import { LANGUAGES } from '../constants'
import { routes } from '../routes'
import { NavLink, useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faBurger } from '@fortawesome/free-solid-svg-icons'
import LanguageContext from '../providers/language-provider'

const propTypes = {
}

const defaultProps = {
}
const BilingualMenu = () => {
  const { selectedLanguage, setSelectedLanguage } = useContext(LanguageContext)

  const [ languageDisplay, setLanguageDisplay ] = useState({})

  const handleClickLanguage = (language) => {
    setLanguageDisplay(language)
    setSelectedLanguage(language.value)
  }

  useEffect(() => {
    const lang = LANGUAGES.find((lang) => lang.value === selectedLanguage)
    setLanguageDisplay(lang)
  }, [ selectedLanguage ])

  return (
    <div className='d-flex align-items-center me-3'>
      <div className="mt-5"/>
      <span className='me-2'>
        { languageDisplay.label }
      </span>
      <ReactCountryFlag
        className='me-2'
        countryCode={ languageDisplay.countryCode }
        svg
        title={ languageDisplay.countryCode }
      />
      <UncontrolledDropdown setActiveFromChild direction={ 'down' }>
        <DropdownToggle
          caret
          className="nav-link"
          tag="a"
          style={{
            cursor: 'pointer'
          }}
        />
        <DropdownMenu
          style={{
            backgroundColor: '#333333'
          }}
        >
          {
            LANGUAGES.map((language, index) => (
              <DropdownItem
                key={ `language-${index}` }
                onClick={ ()=> handleClickLanguage(language) }
              >
                <span
                  className='me-2'
                  style={{ color: 'white' }}
                >
                  { language.label }
                </span>
                <ReactCountryFlag
                  className='me-2'
                  countryCode={ language.countryCode }
                  svg
                  title={ language.countryCode }
                />
              </DropdownItem>
            ))
          }
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  )
}
BilingualMenu.propTypes = propTypes
BilingualMenu.defaultProps = defaultProps

export default BilingualMenu