import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Container } from 'reactstrap'
import { TimeUtil } from '../../utils'
import { useNavigate } from 'react-router-dom'
import LanguageContext from '../../providers/language-provider'

const propTypes = {
  portfolios: PropTypes.array
}

const defaultProps = {
  portfolios: []
}
const ProjectShowcaseSection = ({ portfolios }) => {
  const { selectedLanguage } = useContext(LanguageContext)
  const navigate = useNavigate()
  const handleGoToPortfolioDetail = (portfolio) => {
    const portfolioId = portfolio.id
    navigate(`/portfolios/${portfolioId}`)
  }
  const detail = (thing) => thing.details.find((det)=>det.language.code===selectedLanguage)
  return (
    <section>
      <Container className='my-3'>
        <h2 className='mb-4 mt-1 text-center'>
          <strong>{ 'Project' }</strong>{ ' Showcase' }
        </h2>
        <div
          className='d-flex p-1'
          style={{ overflowX: 'scroll', overflowY: 'hidden' }}
        >
          {
            portfolios.map((portfolio, index) => (
              <div
                key={ `index-${index}` }
                className='p-3 m-3 mx-auto'
                style={{
                  minWidth  : 250,
                  maxWidth  : 250,
                  maxHeight : 300,
                  cursor    : 'pointer'
                }}
                onClick={ () => { handleGoToPortfolioDetail(portfolio) } }
              >
                <div
                  className='rounded shadow p-3 d-flex flex-column'
                  style={{
                    minWidth  : 230,
                    maxWidth  : 250,
                    minHeight : 200,
                    maxHeight : 300,
                    cursor    : 'pointer'
                  }}
                >
                  <img
                    src={ portfolio?.media[0]?.media?.mediaUrl }
                    alt={ `Portfolio Media ${index}` }
                    className='rounded'
                    width={ 200 }
                    height={ 150 }
                    style={{
                      objectFit      : 'cover',
                      objectPosition : 'center center'
                    }}
                  />
                  <div className='small mt-2'>{ TimeUtil.getDayString(portfolio?.eventDate) }</div>
                  <div
                    style={{
                      whiteSpace   : 'nowrap',
                      overflow     : 'hidden',
                      textOverflow : 'ellipsis'
                    }}
                  >
                    { detail(portfolio)?.title }
                  </div>
                </div>
              </div>
            ))
          }
        </div>
      </Container>
    </section>
  )
}
ProjectShowcaseSection.propTypes = propTypes
ProjectShowcaseSection.defaultProps = defaultProps

export default ProjectShowcaseSection