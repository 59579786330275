import React from 'react'
import { Outlet } from 'react-router-dom'
import { Container } from 'reactstrap'
import { BannerLayout, FooterLayout } from '../components'

const DashboardLayout = () => (
  <>
    <BannerLayout />
    <Outlet />
    <FooterLayout/>
  </>
)

export default DashboardLayout