import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import 'bootstrap/dist/css/bootstrap.min.css'
import './styles/theme.scss'
import { BrowserRouter } from 'react-router-dom'
import '@fontsource/montserrat' // Defaults to weight 400
import '@fontsource/montserrat/400.css' // Specify weight
import '@fontsource/montserrat/400-italic.css' // Specify weight and style
import { LanguageProvider } from './providers'

require('./configs/axios-config')

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <BrowserRouter>
    <LanguageProvider>
      <App />
    </LanguageProvider>
  </BrowserRouter>
)
