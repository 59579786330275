import axios from 'axios'

const API_PATH = 'api/public'

const PublicApi = {

  indexBanners: (filters) => {
    const params = {
      location: filters.location
    }
    const path = `${API_PATH}/banners`
    return axios.get(path, { params })
  },
  indexArticles: (filters) => {
    const params = {
      location: filters.location
    }
    const path = `${API_PATH}/articles`
    return axios.get(path, { params })
  },
  indexServices: () => {
    const path = `${API_PATH}/services`
    return axios.get(path)
  },
  showService: (id) => {
    const path = `${API_PATH}/services/${id}`
    return axios.get(path)
  },
  indexClients: () => {
    const path = `${API_PATH}/clients`
    return axios.get(path)
  },
  indexTeamMember: () => {
    const path = `${API_PATH}/team-members`
    return axios.get(path)
  },
  indexCareers: () => {
    const path = `${API_PATH}/careers`
    return axios.get(path)
  },
  indexPortfolios: () => {
    const path = `${API_PATH}/portfolios`
    return axios.get(path)
  },
  indexInternalInfo: () => {
    const path = `${API_PATH}/internal-info`
    return axios.get(path)
  },
  createInquiry: ({ attributes }) => {
    const path = `${API_PATH}/inquiry`
    const body = {
      name        : attributes.name,
      email       : attributes.email,
      phoneNumber : attributes.phoneNumber,
      message     : attributes.message
    }
    return axios.post(path, body)
  }
}

export default PublicApi