import React from 'react'
import PropTypes from 'prop-types'
import { Col, Container, Row } from 'reactstrap'

const propTypes = {
  clients: PropTypes.array
}

const defaultProps = {
  clients: []
}
const ClientsSection = ({ clients }) => {
  const a = []
  return(
    <section>
      <Container className='my-3'>
        <h2 className='mb-4 mt-1 text-center'>
          <strong>{ 'Clients' }</strong>{ ' that worked with us' }
        </h2>
        <div className='my-3'>
          <div className='fw-bold text-center text-secondary mb-4'>
            { 'Our Clients' }
          </div>
          <Row className='justify-content-center'>
            {
              clients.map((client, index) => (
                <Col
                  xs={ 12 }
                  md={ 4 }
                  className='text-center'
                  key={ `client-section-${index}` }
                >
                  <img width={ 300 } src={ client?.media?.mediaUrl } alt="FreePort Client" />
                </Col>
              ))
            }
          </Row>
        </div>
      </Container>
    </section>
  )
}
ClientsSection.propTypes = propTypes
ClientsSection.defaultProps = defaultProps

export default ClientsSection