import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'reactstrap'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faLocationDot, faPhone } from '@fortawesome/free-solid-svg-icons'
import { PublicApi } from '../api'

const FooterLayout = () => {
  const [ address, setAddress ] = useState('Jl. Bangka Raya No. 52, Pela Mampang, Mampang Prapatan, Jakarta Selatan 12760 Indonesia.')
  const [ email, setEmail ] = useState('info@prismakusumajaya.com')
  const [ phone, setPhone ] = useState('62 (21) 717 4096')

  useEffect(() => {
    PublicApi.indexInternalInfo()
      .then((response) => {
        const listInfo = response.data.data
        const adr = listInfo.find((info) => info.infoKey === 'address')
        const ema = listInfo.find((info) => info.infoKey === 'email')
        const pho = listInfo.find((info) => info.infoKey === 'phone')
        if (adr)
          setAddress(adr)
        if (ema)
          setEmail(ema)
        if (pho)
          setPhone(pho)
      })
      .catch((error) => {
        alert('Something is wrong')
        console.log(error)
      })
  },[])
  return (
    <footer
      className={ 'py-5' }
      style={{
        backgroundColor: 'rgb(2, 44, 147)'
      }}
    >
      <Container>
        <div className='d-lg-flex d-block flex-wrap text-center'>
          <Row>
            <Col xs={ 12 } md={ 6 } className='mb-4 mb-md-0'>
              <NavLink
                className={ 'text-decoration-none text-white text-nowrap me-3' }
                to={ '/contact-us' }
              >
                { 'Contact Us' }
              </NavLink>
            </Col>
            <Col xs={ 12 } md={ 6 } className='mb-4 mb-md-0'>
              <NavLink
                className={ 'text-decoration-none text-white text-nowrap me-3' }
                to={ '/portfolios' }
              >
                { 'Portfolio' }
              </NavLink>
            </Col>
          </Row>
          <div className='ms-md-auto text-white'>
            { '© Copyright 2023 PT. Prisma Kusuma Jaya' }
          </div>
        </div>

        <hr className='text-white' />
        <Row>
          <Col
            className='text-white text-center text-md-start'
            xs={ 12 }
            md={ 6 }
          >
            <img src="/logo-prisma.png" alt="Logo Footer" width={ 80 }  className='mb-3'/>
            <div
              className='mb-3'
            >
              <FontAwesomeIcon className={ 'text-white me-3' } icon={ faLocationDot } />
              <span>{ address.infoValue }</span>
            </div>
            <div
              className='mb-3'
            >
              <FontAwesomeIcon className={ 'text-white me-3' } icon={ faEnvelope } />
              <span>{ email.infoValue }</span>
            </div>
            <div
              className='mb-3'
            >
              <FontAwesomeIcon className={ 'text-white me-3' } icon={ faPhone } />
              <span>{ phone.infoValue }</span>
            </div>
          </Col>
          <Col
            className='text-white d-flex justify-content-center d-lg-block'
            xs={ 12 }
            md={ 6 }
          >
            <div
              className='ms-0 ms-md-auto rounded'
              style={{
                position   : 'relative',
                width      : '300px',
                height     : '200px',
                background : '#fff'
              }}
            >
              <div
                className='rounded'
                style={{
                  overflow : 'hidden',
                  width    : '300px',
                  height   : '200px'
                }}
              >
                <iframe
                  className='rounded'
                  src="https://maps.google.com/maps?q=Jl.%20Bangka%20Raya%20No.%2052,%20Pela%20Mampang%20Mampang%20Prapatan%20Jakarta&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed"
                  frameBorder="0"
                  scrolling="no"
                  style={{
                    width    : '300px',
                    height   : '200px',
                    position : 'relative',
                    zIndex   : 2
                  }}
                />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}


export default FooterLayout