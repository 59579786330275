import React, { useContext, useEffect, useState } from 'react'
import ReactCountryFlag from 'react-country-flag'
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'
import { LANGUAGES } from '../constants'
import { routes } from '../routes'
import { NavLink, useLocation } from 'react-router-dom'
import LanguageContext from '../providers/language-provider'

const BilingualMenu = () => {
  const { selectedLanguage, setSelectedLanguage } = useContext(LanguageContext)

  const [ languageDisplay, setLanguageDisplay ] = useState({})

  const handleClickLanguage = (language) => {
    setLanguageDisplay(language)
    setSelectedLanguage(language.value)
  }

  useEffect(() => {
    const lang = LANGUAGES.find((lang) => lang.value === selectedLanguage)
    setLanguageDisplay(lang)
  }, [ selectedLanguage ])

  const router = useLocation()
  const isActiveRoute = (route) => router.pathname==route.path
  return(
    <div className='d-flex justify-content-end align-items-center'>
      <div className="mt-5"/>
      <span className='me-2'>
        { languageDisplay.label }
      </span>
      <ReactCountryFlag
        className='me-2'
        countryCode={ languageDisplay.countryCode }
        svg
        title={ languageDisplay.countryCode }
      />
      <UncontrolledDropdown setActiveFromChild direction={ 'down' }>
        <DropdownToggle
          caret
          className="nav-link"
          tag="a"
          style={{
            cursor: 'pointer'
          }}
        />
        <DropdownMenu style={{
          backgroundColor: '#333333'
        }}
        >
          {
            LANGUAGES.map((language, index) => (
              <DropdownItem
                key={ `language-${index}` }
                onClick={ ()=> handleClickLanguage(language) }
              >
                <span
                  className='me-2'
                  style={{ color: 'white' }}
                >
                  { language.label }
                </span>
                <ReactCountryFlag
                  className='me-2'
                  countryCode={ language.countryCode }
                  svg
                  title={ language.countryCode }
                />
              </DropdownItem>
            ))
          }
        </DropdownMenu>
      </UncontrolledDropdown>
      <span className='mx-3'>
        { '|' }
      </span>
      <NavLink
        to={ '/contact-us' }
        className={ `me-3 ${isActiveRoute(routes.find((route) => route.label==='Contact Us')) ? 'text-primary' : 'text-secondary text-decoration-none'}` }
      >
        { 'Contact Us' }
      </NavLink>
    </div>
  )
}

export default BilingualMenu