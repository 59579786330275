import { useField } from 'formik'
import React from 'react'
import { FormFeedback, FormGroup, FormText, Input, Label } from 'reactstrap'
import PropTypes from 'prop-types'

const propTypes = {
  label    : PropTypes.any,
  formtext : PropTypes.any
}

const InputField = ({
  label,
  formtext,
  ...props
}) => {
  const [ field, meta ] = useField(props)

  const valid = meta.touched && !meta.error
  const invalid = meta.touched && !!meta.error

  return (
    <FormGroup>
      { label && <Label>{ label }</Label> }

      <Input
        valid={ valid }
        invalid={ invalid }
        { ...props }
        { ...field }
      />

      { meta.error && <FormFeedback>{ meta.error }</FormFeedback> }
      { formtext && <FormText>{ formtext }</FormText> }
    </FormGroup>
  )
}
InputField.propTypes = propTypes
export default InputField
