import { AboutUsPage, CareersPage, ContactUsPage, HomePage, PortfoliosPage, ServiceDetailPage, ServicesPage } from '../pages'

const routes = [
  // Homepage
  {
    label           : 'Home',
    path            : '/',
    alternativePath : '/home',
    element         : (<HomePage/>),
    invisible       : false,
    invisibleMobile : true
  },
  {
    label           : 'Home',
    path            : '/home',
    element         : (<HomePage/>),
    invisible       : true,
    invisibleMobile : false
  },
  // About Us
  {
    label           : 'About Us',
    path            : '/about-us',
    element         : (<AboutUsPage/>),
    invisible       : false,
    invisibleMobile : false
  },
  // Our Services
  {
    label           : 'Our Services',
    path            : '/services',
    element         : (<ServicesPage/>),
    invisible       : false,
    invisibleMobile : false
  },
  {
    label           : 'Service Details',
    path            : '/services/:id',
    element         : (<ServiceDetailPage/>),
    invisible       : true,
    invisibleMobile : true
  },
  // Portfolios
  {
    label           : 'Portfolio',
    path            : '/portfolios',
    element         : (<PortfoliosPage />),
    invisible       : false,
    invisibleMobile : false
  },
  {
    label           : 'Portfolio Detail',
    path            : '/portfolios/:id',
    element         : (<PortfoliosPage />),
    invisible       : true,
    invisibleMobile : true
  },
  // Careers
  {
    label           : 'Career',
    path            : '/careers',
    element         : (<CareersPage />),
    invisible       : false,
    invisibleMobile : false
  },
  // Contact Us
  {
    label           : 'Contact Us',
    path            : '/contact-us',
    element         : (<ContactUsPage />),
    invisible       : true,
    invisibleMobile : false
  }
]

export default routes