export const LANGUAGES = [
  {
    name        : 'English',
    label       : 'EN',
    value       : 'EN',
    countryCode : 'GB'
  },
  {
    name        : 'Indonesia',
    label       : 'ID',
    value       : 'ID',
    countryCode : 'ID'
  }
]

export const SAFETY_COM_ARTICLE = {
  INDONESIA:
    '<p>Berdasarkan prestasi dan pengalaman yang kaya di hampir seluruh kepulauan Indonesia, Prisma Kusuma Jaya kini diakui sebagai salah satu perusahaan EPC terkemuka di Indonesia. Kami menawarkan keunggulan kompetitif melalui kemampuan kami untuk menyediakan solusi pertambangan dari tambang ke pelabuhan yang lengkap, kemampuan rekayasa dan konstruksi terintegrasi, serta dukungan logistik. </p>' +
    '<p>Tujuan bersama kami adalah menghilangkan semua cedera, penyakit akibat pekerjaan, praktik-praktik yang tidak aman, dan insiden kerusakan lingkungan dari aktivitas kami.</p>' +
    '<p>Kami berkomitmen untuk bekerja sama dengan mitra global untuk membangun satu tim yang kuat untuk tumbuh lebih besar di pasar ini.</p>',
  ENGLISH:
    '<p>Drawing on rich achievements and experience throughout almost the entire Indonesian archipelago, Prisma Kusuma Jaya is now recognized as one of Indonesia’s leading EPC Company. We offer a competitive advantage through our ability to provide complete pit-to-port mining solutions, integrated engineering and construction capabilities as well as logistic support. </p>'+
    '<p>Our Colective goal is to eliminate all injuries,occupational illnesses,unsafe practices and incidents of environmental harm from our activities. </p>'+
    '<p>We are committed to working to gather with global partner to build one solid team to grow more in this market.</p>'
}