import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Card, CardBody, Collapse, Container } from 'reactstrap'
import { PublicApi } from '../api'
import { useLocation } from 'react-router-dom'
import LanguageContext from '../providers/language-provider'

const propTypes = {
}

const defaultProps = {
}
const CareersPage = () => {
  const [ article, setArticle ] = useState({})
  const [ careers, setCareers ] = useState([])
  const [ selectedCareer, setSelectedCareer ] = useState({})

  const { selectedLanguage } = useContext(LanguageContext)
  const location = useLocation()
  const locationName = location.pathname.split('/')

  useEffect(() => {
    PublicApi.indexArticles({ location: locationName[ 1 ] })
      .then((response) => {
        const listArticles = response.data.data
        setArticle(listArticles[0])
      })
      .catch(() => {
        alert('Something is Wrong')
      })

    PublicApi.indexCareers()
      .then((response) => {
        const listCareers = response.data.data
        setCareers(listCareers)
      })
      .catch(() => {
        alert('Something is Wrong')
      })
  }, [])

  const detail = (thing) => thing?.details?.find((det) => det.language.code === selectedLanguage)

  const handleOpenCollapse = (career) => {
    if (selectedCareer === career) {
      setSelectedCareer({})
      return
    }
    setSelectedCareer(career)
  }
  const isCollapseOpen = (career) => selectedCareer===career
  return(
    <>
      <section className='py-5'>
        <Container>
          <h2 className='text-primary'>{ detail(article)?.title }</h2>
          <div className='mb-4'>
            <span dangerouslySetInnerHTML={{ __html: detail(article)?.content }} />
          </div>
          {
            careers?.map((career, index) => (
              <div
                key={ `career-list-${index}` }
                className='p-3 mb-4 rounded shadow'
              >
                <div className={ 'd-lg-flex justify-content-between' }>
                  <div>
                    <div className={ 'fw-bold' }>
                      { detail(career)?.name }
                    </div>
                    <div>
                      { career?.location }
                    </div>
                    <div className='small'>
                      { `(${career?.jobType})` }
                    </div>
                  </div>
                  <Button
                    color={ 'primary' }
                    className='px-5 mt-3 mt-md-0'
                    onClick={ ()=>{handleOpenCollapse(career)} }
                  >
                    { isCollapseOpen(career) ? 'Hide' : 'Read More' }
                  </Button>
                </div>
                <Collapse isOpen={ isCollapseOpen(career) } className='border-0'>
                  <div className='border-top mt-3 p-3'>
                    <div dangerouslySetInnerHTML={{ __html: detail(career)?.content }} />
                  </div>
                </Collapse>
              </div>
            ))
          }

        </Container>
      </section>
    </>
  )
}
CareersPage.propTypes = propTypes
CareersPage.defaultProps = defaultProps

export default CareersPage