export const TimeUtil = {

  timeConverter: (UNIX_timestamp) => {
    var a = new Date(UNIX_timestamp)
    var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
    var year = a.getFullYear()
    var month = months[a.getMonth()]
    var date = a.getDate()
    var hour = a.getHours()
    var min = a.getMinutes()
    var sec = a.getSeconds()
    var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec
    return time
  },
  getYear: (UNIX_timestamp) => {
    var a = new Date(UNIX_timestamp)
    var year = a.getFullYear()
    return year
  },
  getDayString: (UNIX_timestamp) => {
    var a = new Date(UNIX_timestamp)
    var months = ['January','February','March','April','May','June','July','August','September','October','November','December']
    var year = a.getFullYear()
    var month = months[a.getMonth()]
    var date = a.getDate()
    var time = month + ' ' + date +', ' + year
    return time
  }
}