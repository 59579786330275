import React from 'react'
import PropTypes from 'prop-types'
import { Button, Container } from 'reactstrap'
import { useNavigate } from 'react-router-dom'

const propTypes = {
  articleDetail: PropTypes.object
}

const defaultProps = {
  articleDetail: {}
}
const WhoWeAreSection = ({ articleDetail }) => {
  const navigate = useNavigate()
  const handleClick = () => {
    navigate('/about-us')
  }
  return (
    < section
      className='my-5 text-center'
    >
      <Container>
        <h2 className='text-secondary fw-bold mb-4'>
          { articleDetail.title }
        </h2>
        <h3 className='text-primary fw-light'>
          { articleDetail.summary }
        </h3>
        <span dangerouslySetInnerHTML={{ __html: articleDetail.content }} />
        <Button
          color='primary'
          outline
          className='rounded mt-5 p-3'
          onClick={ handleClick }
        >
          { 'See Details' }
        </Button>
      </Container>
    </section >
  )
}
WhoWeAreSection.propTypes = propTypes
WhoWeAreSection.defaultProps = defaultProps

export default WhoWeAreSection