import { createContext, useState } from 'react'
import PropTypes from 'prop-types'

const authData = {
  selectedLanguage    : 'English',
  setSelectedLanguage : ()=>{}
}

const LanguageContext = createContext({ authData })

/**
 * A provider to pass alertList data, showAlert, hideAlert through the component tree.
 * @param {element} children - Children Component
 */
const propTypes = {
  children: PropTypes.element
}

export const LanguageProvider = ({ children }) => {
  const [ selectedLanguage, setSelectedLanguage ] = useState('EN')

  return (
    <LanguageContext.Provider
      value={{
        selectedLanguage,
        setSelectedLanguage
      }}
    >
      { children }
    </LanguageContext.Provider>
  )
}

LanguageProvider.propTypes = propTypes
export default LanguageContext