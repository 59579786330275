import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Col, Container, Row } from 'reactstrap'
import { useNavigate } from 'react-router-dom'
import style from './service-section.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding, faChevronCircleDown } from '@fortawesome/free-solid-svg-icons'
import LanguageContext from '../../providers/language-provider'

const propTypes = {
  services: PropTypes.array
}

const defaultProps = {
  services: []
}
const ServiceSection = ({ services }) => {
  const { selectedLanguage } = useContext(LanguageContext)
  const [ clickedService, setClickedService ] = useState({})
  const navigate = useNavigate()

  const handleHoverService = (service) => {
    setClickedService(service)
  }
  const handleClickService = (service) => {
    navigate(`/services/${service.id}`)
  }
  const isServiceClicked = (service) => clickedService === service

  const detail = (service) => service.details.find((det)=>det.language.code===selectedLanguage)
  return (
    <section
      className='position-relative'
      style={{
        width: '100%'
      }}
    >
      <img
        className='position-absolute'
        src="/background.jpg"
        alt="Background Banner"
        width={ '100%' }
        height={ '100%' }
        style={{
          zIndex               : -3,
          maxHeight            : '100%',
          objectFit            : 'cover',
          objectPosition       : 'center top',
          WebkitMaskAttachment : 'fixed'
        }}
      />
      <div
        className='position-absolute'
        style={{
          zIndex          : -2,
          opacity         : '70%',
          backgroundColor : 'white',
          width           : '100%',
          height          : '100%'
        }}
      />
      <Container className='py-5 text-center'>
        <h2 className='text-secondary mb-4 mt-1'>
          <strong>{ 'Services' }</strong>{ ' that we prioritize' }
        </h2>
        <Row
          className='my-3 text-secondary py-3'
        >
          {
            services.map((service, index) => (
              <Col
                key={ `service-${index}` }
                xs={ 12 }
                md={ 4 }
                className='d-flex align-items-end justify-content-center '
                style={{
                  minHeight : '500px',
                  maxHeight : '500px'
                }}
              >
                <div
                  className={ `${style.serviceBlock} ${isServiceClicked(service) && style.serviceAimation}` }
                  style={{
                    cursor : 'pointer',
                    width  : '300px'
                  }}
                  onClick={ () => { handleHoverService(service) } }
                >
                  <img
                    className='rounded-circle'
                    src={ service?.icon?.mediaUrl }
                    alt={ `service-icon-${index}` }
                    width={ 200 }
                    height={ 200 }
                    style={{
                      objectFit      : 'cover',
                      objectPosition : 'center center'
                    }}
                  />
                  <div className='h3 d-inline'>
                    { service.originalTitle }
                    {
                      !isServiceClicked(service) && <FontAwesomeIcon className={ 'ms-2' } icon={ faChevronCircleDown } size='xs' />
                    }
                  </div>
                  {
                    isServiceClicked(service) && (
                      <>
                        <div className='py-4'>
                          { detail(service).subtitle }
                        </div>
                        <Button
                          outline
                          color='primary'
                          onClick={ () => { handleClickService(service) } }
                        >
                          See More
                        </Button>
                      </>
                    )

                  }
                </div>
              </Col>
            ))
          }
        </Row>
      </Container>
    </section>
  )
}
ServiceSection.propTypes = propTypes
ServiceSection.defaultProps = defaultProps

export default ServiceSection