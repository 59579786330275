import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Badge, Button, Container, Modal, ModalBody } from 'reactstrap'
import { PublicApi } from '../api'
import LanguageContext from '../providers/language-provider'
import dayjs from 'dayjs'
import { TimeUtil } from '../utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faLocationDot, faLocationPin } from '@fortawesome/free-solid-svg-icons'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

const propTypes = {
}

const defaultProps = {
}
const PortfoliosPage = () => {
  const { id } = useParams()
  const [ article, setArticle ] = useState({})
  const [ isModalOpen, setIsModalOpen ] = useState(false)
  const [ portfolios, setPortfolios ] = useState([])
  const { selectedLanguage } = useContext(LanguageContext)
  const [ selectedPortfolio, setSelectedPortfolio ] = useState({})
  const [ selectedImage, setSelectedImage ] = useState({})

  const navigate = useNavigate()
  const location = useLocation()
  const locationName = location.pathname.split('/')

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }

  useEffect(() => {
    if (portfolios.length<1)
      return
    if (!id)
      return

    const thisPortfolio = portfolios?.find((port) => port.id == id)
    setSelectedPortfolio(thisPortfolio)
    setSelectedImage(thisPortfolio.media[0])
    toggleModal()
  }, [id, portfolios])

  useEffect(() => {
    PublicApi.indexArticles({ location: locationName[ 1 ] })
      .then((response) => {
        const listArticles = response.data.data
        setArticle(listArticles[0])
      })
      .catch(() => {
        alert('Something is Wrong')
      })

    PublicApi.indexPortfolios()
      .then((response) => {
        const listPortfolio = response.data.data
        setPortfolios(listPortfolio)
      }).catch(()=>alert('Something is wrong'))
  }, [])

  const detail = (portfolio) => portfolio?.details?.find((det) => det.language.code === selectedLanguage)

  const handleCloseModal = () => {
    toggleModal()
    navigate('/portfolios')
  }

  const handleClickReadMore = (portfolio) => {
    setSelectedPortfolio(portfolio)
    setSelectedImage(portfolio.media[0])
    toggleModal()
  }

  const handleClickImagePortfolios = (image) => {
    setSelectedImage(image)
  }

  const isImageSelected = (image)=> selectedImage===image
  return (
    <>
      <section className='py-5'>
        <Container>
          <h2 className='text-primary'>{ detail(article)?.title }</h2>
          <div className='mb-4'>
            <span dangerouslySetInnerHTML={{ __html: detail(article)?.content }} />
          </div>

          <div
            className='d-flex'
            style={{
              overflow  : 'hidden',
              overflowX : 'scroll'
            }}
          >
            {
              portfolios.map((portfolio,index) => (
                <div
                  key={ `portfolio-card-${index}` }
                  className='rounded shadow m-4 position-relative'
                  style={{
                    minHeight : 270,
                    minWidth  : 270
                  }}
                >
                  <div
                    className='rounded'
                    style={{
                      position           : 'absolute',
                      zIndex             : -2,
                      minWidth           : '100%',
                      minHeight          : '100%',
                      backgroundImage    : `url(${portfolio?.media[0]?.media?.mediaUrl})`,
                      backgroundSize     : '500px auto',
                      backgroundRepeat   : 'no-repeat',
                      backgroundPosition : 'center center',
                      objectFit          : 'contain',
                      objectPosition     : 'center center'
                    }}
                  />
                  <div
                    className='rounded'
                    style={{
                      position        : 'absolute',
                      zIndex          : -1,
                      minWidth        : '100%',
                      minHeight       : '100%',
                      backgroundColor : 'white',
                      opacity         : '70%'
                    }}
                  />
                  <div className='p-3 rounded d-flex flex-column h-100'>
                    <div className='fw-bold'>
                      { detail(portfolio)?.title }
                    </div>
                    <div className='small'>
                      { portfolio?.clientName }
                    </div>
                    <div className='small'>
                      { TimeUtil.getYear(portfolio?.eventDate) }
                    </div>
                    <Badge
                      className='px-3 w-50'
                      color='warning'
                    >
                      { portfolio?.type }
                    </Badge>
                    <Button
                      block
                      color='primary'
                      className='rounded p-2 px-5 mt-auto'
                      onClick={ () => handleClickReadMore(portfolio) }
                    >
                      { 'Read More' }
                    </Button>
                  </div>
                </div>
              ))
            }
            <div>
            </div>
          </div>
        </Container>
      </section>

      <Modal
        isOpen={ isModalOpen }
        toggle={ toggleModal }
        centered
        size={ 'lg' }
      >
        <ModalBody
          className='d-flex flex-column p-3'
        >
          <Button
            className='ms-auto rounded align-items-end mb-4'
            color='danger'
            onClick={ handleCloseModal }
          >
            { 'X' }
          </Button>
          <div className='d-flex justify-content-evenly mb-4'>
            <div>
              <div className='fw-bold h3'>{ detail(selectedPortfolio)?.title }</div>
              <Badge className='px-3' color='warning'>{ selectedPortfolio.type }</Badge>
            </div>
            <div>
              <div>{ selectedPortfolio?.clientName }</div>
              <div>
                <FontAwesomeIcon className='me-2' icon={ faLocationDot } />
                { selectedPortfolio?.location }
              </div>
              <div>
                <FontAwesomeIcon className='me-2' icon={ faCalendar } />
                { TimeUtil.getYear(selectedPortfolio?.eventDate) }
              </div>
            </div>
          </div>
          <img
            className='rounded mx-auto'
            src={ selectedImage?.media?.mediaUrl }
            alt={ 'Selected Portofolio Photo' }
            width={ '100%' }
            height={ 'auto' }
          />
          <div
            className='d-flex py-3 align-self-center'
            style={{
              overflow  : 'hidden',
              overflowX : 'scroll',
              maxWidth  : 400
            }}
          >
            {
              selectedPortfolio?.media?.map((image, index) => (
                <div key={ `portfolio-image-${index}` } className='position-relative'>
                  <img
                    className='rounded mx-auto me-3'
                    src={ image?.media?.mediaUrl }
                    alt={ 'Selected Portofolio Photo' }
                    width={ 100 }
                    height={ 100 }
                    style={{
                      objectFit      : 'cover',
                      objectPosition : 'center center',
                      zIndex         : 1
                    }}
                  />
                  {
                    !isImageSelected(image) && (
                      <div
                        onClick={ ()=>handleClickImagePortfolios(image) }
                        style={{
                          backgroundColor : 'white',
                          opacity         : '90%',
                          zIndex          : 9,
                          position        : 'absolute',
                          width           : 100,
                          height          : 100,
                          top             : 0,
                          bottom          : 0,
                          left            : 0,
                          right           : 0,
                          cursor          : 'pointer'
                        }}
                      />
                    )
                  }
                </div>
              ))
            }
          </div>

        </ModalBody>
      </Modal>
    </>
  )
}
PortfoliosPage.propTypes = propTypes
PortfoliosPage.defaultProps = defaultProps

export default PortfoliosPage