import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Alert, Button, Container, FormGroup, Input, Label } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faLocationDot, faPhone } from '@fortawesome/free-solid-svg-icons'
import { InputField } from '../components'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { PublicApi } from '../api'
import { LANGUAGES } from '../constants'
import LanguageContext from '../providers/language-provider'

const propTypes = {
}

const defaultProps = {
}

const ALERT_LABEL = {
  [ LANGUAGES[ 0 ].value ] : 'Successful sending inquiry.',
  [ LANGUAGES[1].value ]   : 'Berhasil mengirim pertanyaan.'
}
const ContactUsPage = () => {
  const { selectedLanguage } = useContext(LanguageContext)
  const [ isChecked, setIsChecked ] = useState(false)
  const [ isInquiryCreated, setIsInquiryCreated ] = useState(false)

  const [ address, setAddress ] = useState('Jl. Bangka Raya No. 52, Pela Mampang, Mampang Prapatan, Jakarta Selatan 12760 Indonesia.')
  const [ email, setEmail ] = useState('info@prismakusumajaya.com')
  const [ phone, setPhone ] = useState('62 (21) 717 4096')

  useEffect(() => {
    PublicApi.indexInternalInfo()
      .then((response) => {
        const listInfo = response.data.data
        const adr = listInfo.find((info) => info.infoKey === 'address')
        const ema = listInfo.find((info) => info.infoKey === 'email')
        const pho = listInfo.find((info) => info.infoKey === 'phone')
        if (adr)
          setAddress(adr)
        if (ema)
          setEmail(ema)
        if (pho)
          setPhone(pho)
      })
      .catch((error) => {
        alert('Something is wrong')
        console.log(error)
      })
  }, [])

  const handleCheckAgreement = () => {
    setIsChecked(!isChecked)
  }

  const handleCreateInquiry = (values, formik) => {
    PublicApi.createInquiry({ attributes: values })
      .then(() => {
        setIsInquiryCreated(true)
      })
      .catch(() => {
        alert('Something is wrong')
      })
  }
  return(
    <section className='py-5'>
      <Container className='d-lg-flex'>
        <div>
          <h2 className='fw-bold mb-4'>{ 'Our Office' }</h2>
          <div
            className='rounded'
            style={{
              position   : 'relative',
              width      : '300px',
              height     : '200px',
              background : '#fff'
            }}
          >
            <div
              className='rounded'
              style={{
                overflow : 'hidden',
                width    : '300px',
                height   : '200px'
              }}
            >
              <iframe
                className='rounded'
                src="https://maps.google.com/maps?q=Jl.%20Bangka%20Raya%20No.%2052,%20Pela%20Mampang%20Mampang%20Prapatan%20Jakarta&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed"
                frameBorder="0"
                scrolling="no"
                style={{
                  width    : '300px',
                  height   : '200px',
                  position : 'relative',
                  zIndex   : 2
                }}
              />
            </div>

          </div>
          <div className='mt-4'
            style={{
              maxWidth: 500
            }}
          >
            <div
              className='mb-3'
            >
              <FontAwesomeIcon className={ 'me-3' } icon={ faLocationDot } />
              <span>{ address.infoValue }</span>
            </div>
            <div
              className='mb-3'
            >
              <FontAwesomeIcon className={ 'me-3' } icon={ faEnvelope } />
              <span>{ email.infoValue }</span>
            </div>
            <div
              className='mb-3'
            >
              <FontAwesomeIcon className={ 'me-3' } icon={ faPhone } />
              <span>{ phone.infoValue }</span>
            </div>
          </div>
        </div>
        <Formik
          className={ 'w-100' }
          enableReinitialize={ true }
          initialValues={{
            name        : '',
            email       : '',
            phoneNumber : '',
            message     : ''
          }}
          validationSchema={
            Yup.object().shape({
              name        : Yup.string().required('Name is Required'),
              email       : Yup.string().required('Email is Required'),
              phoneNumber : Yup.string(),
              message     : Yup.string().required('Message is Required')
            })
          }
          onSubmit={ handleCreateInquiry }
        >
          {
            (formik) => (
              <Form className='w-100 ps-lg-5 ps-0 mt-5 mt-lg-0'>
                <InputField
                  className={ 'w-100' }
                  label={ 'Name' }
                  type={ 'text' }
                  name={ 'name' }
                />
                <InputField
                  label={ 'Email' }
                  type={ 'text' }
                  name={ 'email' }
                />
                <InputField
                  label={ 'Phone Number' }
                  type={ 'tel' }
                  name={ 'phoneNumber' }
                />
                <InputField
                  label={ 'Message' }
                  type={ 'textarea' }
                  name={ 'message' }
                />
                <FormGroup className='d-flex'>
                  <Input
                    className={ 'me-3' }
                    type={ 'checkbox' }
                    name={ 'isChecked' }
                    onChange={ handleCheckAgreement }
                    disabled={ isInquiryCreated }
                  />
                  <Label>{ 'Agree to Submit the Inquiry' }</Label>
                </FormGroup>
                <Button
                  className="mb-4 px-4"
                  type="submit"
                  color={ 'primary' }
                  disabled={ !isChecked || isInquiryCreated }
                >
                  { 'Submit' }
                </Button>
                {
                  isInquiryCreated && (
                    <Alert color="success">
                      { ALERT_LABEL[selectedLanguage] }
                    </Alert>
                  )
                }
                {

                }
              </Form>
            )
          }
        </Formik>
      </Container>
    </section>
  )
}
ContactUsPage.propTypes = propTypes
ContactUsPage.defaultProps = defaultProps

export default ContactUsPage