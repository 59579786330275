import React, { useContext, useEffect, useState } from 'react'
import { PublicApi } from '../api'
import LanguageContext from '../providers/language-provider'
import { ClientsSection, ProjectShowcaseSection, SafetyCommitmentSection, ServiceSection, WhoWeAreSection } from '../domains/homepage'

const HomePage = () => {
  const [ article, setArticle ] = useState([])
  const [ portfolios, setPortfolios ] = useState([])
  const { selectedLanguage } = useContext(LanguageContext)
  const [ services, setServices ] = useState([])
  const [ clients, setClients ] = useState([])
  const pageLocation = 'home'

  useEffect(() => {
    PublicApi.indexArticles({ location: pageLocation })
      .then((response) => {
        const listArticle = response.data.data
        setArticle(listArticle[0])
      })
      .catch(() => {
        alert('Something is wrong')
      })

    PublicApi.indexServices()
      .then((response) => {
        const listService = response.data.data
        setServices(listService)
      })
      .catch(() => {
        alert('Something is wrong')
      })

    PublicApi.indexClients()
      .then((response) => {
        const listClient = response.data.data
        setClients(listClient)
      })
      .catch(() => {
        alert('Something is wrong')
      })
    PublicApi.indexPortfolios()
      .then((response) => {
        const listPortfolios = response.data.data
        setPortfolios(listPortfolios)
      })
      .catch(() => {
        alert('Something is wrong')
      })
  }, [])

  const detail = (thing) => thing.details.find((det)=>det.language.code===selectedLanguage)


  return (
    <>
      { /* WHO WE ARE SECTION START */ }
      {
        Object.keys(article).length > 0 && (
          < WhoWeAreSection articleDetail={ detail(article) } />
        )
      }
      { /* WHO WE ARE SECTION END */ }

      { /* SERVICE SECTION START */ }
      <ServiceSection services={ services }/>
      { /* SERVICE SECTION END */ }

      { /* SAFETY COMMITMENT SECTION START */ }
      <SafetyCommitmentSection selectedLanguage={ selectedLanguage } />
      { /* SAFETY COMMITMENT SECTION END */ }

      { /* PROJECT SHOWCASE SECTION START */ }
      <ProjectShowcaseSection portfolios={ portfolios } />
      { /* PROJECT SHOWCASE SECTION START */ }

      { /* CLIENTS SECTION START */ }
      <ClientsSection clients={ clients } />
      { /* CLIENTS SECTION START */ }
    </>
  )
}
export default HomePage