import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { PublicApi } from '../api'
import { Col, Container, Row } from 'reactstrap'
import LanguageContext from '../providers/language-provider'

const propTypes = {
}

const defaultProps = {
}
const ServiceDetailPage = () => {
  const { id } = useParams()
  const [ service, setService ] = useState({})
  const { selectedLanguage } = useContext(LanguageContext)
  useEffect(() => {
    PublicApi.showService(id)
      .then((response) => {
        const responseService = response.data.data
        setService(responseService)
      })
      .catch(() => {
        alert('Something is wrong')
      })
  }, [id])

  const detail = (article) => article?.details?.find((det) => det.language.code === selectedLanguage)
  return(
    <>
      <section className='py-5'>
        <Container style={{ overflow: 'hidden' }}>
          <h2 className='text-primary'>{ detail(service)?.subtitle }</h2>
          <Row>
            <Col xs={ 12 } md={ 6 }>
              <span dangerouslySetInnerHTML={{ __html: detail(service)?.content }} />
            </Col>
            <Col xs={ 12 } md={ 6 }>
              <img
                src={ service?.media?.mediaUrl }
                alt={ 'About Us Image' }
                className={ 'rounded' }
                width={ '100%' }
                height={ 'auto' }
                style={{
                  objectFit      : 'cover',
                  objectPosition : 'center center'
                }}
              />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}
ServiceDetailPage.propTypes = propTypes
ServiceDetailPage.defaultProps = defaultProps

export default ServiceDetailPage